import React, { useState, useEffect } from 'react';

const ChatWithGpt = () => {
  const [message, setMessage] = useState('');
  const [chatLog, setChatLog] = useState([]);
  const [loading, setLoading] = useState(false);

  // Load the chat log from the server when the component mounts
  useEffect(() => {
    const loadChatLog = async () => {
      try {
        const response = await fetch('https://thing3.wearecryptoradio.com/api/get-chat-log', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ conversationId: 'default_conversation' }),
        });
        const data = await response.json();
        setChatLog(data);
      } catch (error) {
        console.error('Failed to load chat log:', error);
      }
    };

    loadChatLog();
  }, []);

  const handleSendMessage = async () => {
    if (message.trim() === '') return;

    const newChatLog = [...chatLog, { sender: 'user', text: message }];
    
    // Keep only the last 10 messages
    if (newChatLog.length > 10) {
      newChatLog.shift();
    }

    setChatLog(newChatLog);
    setMessage('');

    setLoading(true);
    try {
      const response = await fetch('https://thing3.wearecryptoradio.com/api/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          conversationId: 'default_conversation',
          message,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const gptMessage = data.message.trim();

      const updatedChatLog = [...newChatLog, { sender: 'gpt', text: gptMessage }];

      setChatLog(updatedChatLog);
    } catch (error) {
      console.error('Error communicating with GPT:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-log">
        {chatLog.map((entry, index) => (
          <div key={index} className={`chat-message ${entry.sender}`}>
            <p>{entry.text}</p>
          </div>
        ))}
      </div>
      <div className="chat-input">
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message here..."
        ></textarea>
        <button onClick={handleSendMessage} disabled={loading}>
          {loading ? 'Sending...' : 'Send'}
        </button>
      </div>
    </div>
  );
};

export default ChatWithGpt;
