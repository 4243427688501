import React, { useEffect, useRef, useState } from 'react';
import { DataSet, Timeline } from 'vis-timeline/standalone';
import 'vis-timeline/styles/vis-timeline-graph2d.min.css';
import './TimeLine.css';
import events from './timelineEvents.json';
import historical from './timelineHistorical.json';

const TimeLine = () => {
  const timelineRef = useRef(null);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const container = timelineRef.current;

    // Manually adjust the historical data to ensure correct date handling
    const adjustedHistoricalData = historical.map(item => {
      if (typeof item.start === 'string' && item.start.startsWith('new Date')) {
        return { ...item, start: eval(item.start) };
      }
      return item;
    });

    // Merge the two JSON data arrays
    const mergedData = [...events, ...adjustedHistoricalData];

    const items = new DataSet(mergedData);

    const options = {
      width: '100%',
      height: '300px',
      zoomable: true,
      moveable: true,
      stack: true,
      showMajorLabels: true,
      showCurrentTime: true,
      editable: false,
      margin: {
        item: 10
      },
      orientation: {
        axis: 'top',
        item: 'bottom'
      },
      selectable: true,
      tooltip: {
        followMouse: true
      },
      maxHeight: '50vh'
    };

    const timeline = new Timeline(container, items, options);

    timeline.on('select', function (properties) {
      if (properties.items.length > 0) {
        const selectedItem = items.get(properties.items[0]);
        setSelectedItem(selectedItem);
      } else {
        setSelectedItem(null);
      }
    });

    return () => {
      timeline.destroy();
    };
  }, []);

  return (
    <div className="timeline-container">
      <h1 className="timeline-title">The Conspiracy Timeline</h1>
      {selectedItem && (
        <div className="timeline-details">
          <h2>{selectedItem.content}</h2>
          <p><strong>Date:</strong> {selectedItem.start.toString()}</p>
          <p><strong>Category:</strong> {selectedItem.group}</p>
        </div>
      )}
      <div ref={timelineRef}></div>
    </div>
  );
};

export default TimeLine;
