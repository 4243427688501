import React, { useState, useEffect } from 'react';
import './CommunityPage.css';

const CommunityPage = () => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);

  // Fetch existing messages from the backend
  useEffect(() => {
    fetch('https://thing3.wearecryptoradio.com/get-messages')
      .then(response => response.json())
      .then(data => setMessages(data))
      .catch(error => console.error('Failed to load messages:', error));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message.trim() === '') return;
  
    const newMessage = { text: message };
    setMessage('');
  
    try {
      const response = await fetch('https://thing3.wearecryptoradio.com/save-message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newMessage),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
  
      // Fetch the updated messages immediately after posting
      const updatedMessages = await fetch('https://thing3.wearecryptoradio.com/get-messages').then(res => res.json());
      setMessages(updatedMessages);
    } catch (error) {
      console.error('Failed to post message:', error);
    }
  };
  

  return (
    <div className="community-container">
      <div className="community-title">Community Board</div>
      <div className="beta-note">This page is Beta and features are being implemented.</div>
      
      <form onSubmit={handleSubmit} className="message-form">
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Leave an anonymous note..."
          className="message-input"
        ></textarea>
        <button type="submit" className="submit-button">Post</button>
      </form>
      
      <div className="messages-container">
        {messages.map((msg, index) => (
          <div key={index} className="message">
            <div>{msg.text}</div>
            <div className="timestamp">
              {new Date(msg.timestamp).toLocaleString()}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommunityPage;
