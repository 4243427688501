import React from 'react';
import './SearchPage.css'; // Create this CSS file for custom styles

const SearchPage = () => {
  return (
    <div className="search-container">
      <div className="search-title">SEARCH ALL CASES</div>
      <div className="search-bar-container">
        <input type="text" className="search-bar" placeholder="Search cases..." />
      </div>
      <div className="under-construction">
        This feature is currently under construction. Please check back later!
      </div>
    </div>
  );
};

export default SearchPage;
