import React, { useState } from 'react';
import './EarlyAccessPage.css';

const EarlyAccessPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Registration feature is under development.');
  };

  return (
    <div className="early-access-container">
      <div className="early-access-title">Early Access Registration</div>
      <div className="under-development-note">
        This page is currently under development. Please check back later!
      </div>
      <form onSubmit={handleSubmit} className="early-access-form">
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Name"
          className="early-access-input"
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="early-access-input"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="early-access-input"
        />
        <button type="submit" className="early-access-button">
          Register
        </button>
      </form>
    </div>
  );
};

export default EarlyAccessPage;
