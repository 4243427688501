import React from 'react';
import './Splash.css';
import { useNavigate } from 'react-router-dom';

const Splash = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    const logoContainer = document.querySelector('.logo-container');
    const splashContainer = document.querySelector('.splash-container');

    // Trigger the grow animation
    logoContainer.classList.add('grow-animation');

    // After the grow animation, trigger the fade-out effect
    setTimeout(() => {
      splashContainer.classList.add('fade-out');
    }, 1500); // Delay matches the grow animation duration

    // After the fade-out completes, navigate to the loading screen
    setTimeout(() => {
      navigate('/loading'); // Navigate to the loading screen
    }, 2500); // Combined delay for grow + fade-out animation
  };

  return (
    <div className="splash-container" onClick={handleClick}>
      <div className="splash-text">Site Loading...</div>
      <div className="logo-container">
        <div className="ripple"></div>
        <div className="ripple"></div>
        <div className="ripple"></div>
        <div className="ripple"></div>
        <div className="ripple"></div>
      </div>
      <div className="splash-continue">Continue</div>
    </div>
  );
};

export default Splash;
