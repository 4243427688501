import React from 'react';
import ChatWithGpt from './ChatWithGpt';
import './ChatWithGptPage.css'; // Import the custom CSS for styling

const ChatWithGptPage = () => {
  return (
    <div className="chat-gpt-page">
      <div className="chat-container">
        <ChatWithGpt />
      </div>
    </div>
  );
}

export default ChatWithGptPage;
