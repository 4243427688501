import React from 'react';
import { Link } from 'react-router-dom';
import './index.css';

const Home = () => {
  return (
    <div className="background">
      <div className="logo-container">
        <img src={`${process.env.PUBLIC_URL}/sine_wave_logo.png`} alt="Sine Wave Logo" className="sine-wave-logo" />
      </div>

      <div className="button-container">
        <div className="button">
          <Link to="/search" style={{ textDecoration: 'none', color: 'white' }}>
            <p>SEARCH ALL CASES</p>
          </Link>
        </div>
        <div className="button">
          <Link to="/explore-data" style={{ textDecoration: 'none', color: 'white' }}>
            <p>EXPLORE DATA</p>
          </Link>
        </div>
        <div className="button">
          <Link to="/interactive-map" style={{ textDecoration: 'none', color: 'white' }}>
            <p>INTERACTIVE MAP</p>
          </Link>
        </div>
        <div className="button">
          <Link to="/community" style={{ textDecoration: 'none', color: 'white' }}>
            <p>COMMUNITY</p>
          </Link>
        </div>
        <div className="button"> 
          <Link to="/chat-with-gpt" style={{ textDecoration: 'none', color: 'white' }}>
            <p>CHAT WITH GPT</p>
          </Link>
        </div>
      </div>

      <div className="coming-soon">COMING SOON</div>

      <div className="bottom-buttons">
        <div className="bottom-button">
          <Link to="/early-access" style={{ textDecoration: 'none', color: 'white' }}>
            EARLY ACCESS
          </Link>
        </div>
        <div className="bottom-button">DONATE</div>
      </div>
    </div>
  );
}

export default Home;
