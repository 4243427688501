import React from 'react';
import { Link } from 'react-router-dom';
import './ExploreData.css';

const ExploreData = () => {
  const conspiracies = [
    {
      title: "Moon Landing Hoax",
      description: "Covers the theories claiming that the Apollo Moon landings were faked by NASA.",
      link: "https://en.wikipedia.org/wiki/Moon_landing_conspiracy_theories"
    },
    {
      title: "Area 51 and Aliens",
      description: "Explores the secretive nature of Area 51 and its association with alien conspiracy theories.",
      link: "https://en.wikipedia.org/wiki/Area_51"
    },
    {
      title: "JFK Assassination",
      description: "Details the events surrounding the assassination of President John F. Kennedy, including conspiracy theories.",
      link: "https://en.wikipedia.org/wiki/Assassination_of_John_F._Kennedy"
    },
    {
      title: "9/11 Inside Job",
      description: "Discusses the conspiracy theories suggesting that the 9/11 attacks were an inside job.",
      link: "https://en.wikipedia.org/wiki/September_11_attacks_conspiracy_theories"
    },
    {
      title: "The Illuminati",
      description: "Covers the history and modern conspiracy theories related to the Illuminati.",
      link: "https://en.wikipedia.org/wiki/Illuminati"
    },
    {
      title: "Flat Earth Theory",
      description: "Describes the belief in a flat Earth, which contrasts with modern scientific consensus.",
      link: "https://en.wikipedia.org/wiki/Flat_Earth"
    },
    {
      title: "Chemtrails",
      description: "Addresses the conspiracy theory that some airplane contrails are actually chemical or biological agents.",
      link: "https://en.wikipedia.org/wiki/Chemtrail_conspiracy_theory"
    },
    {
      title: "The Roswell Incident",
      description: "Details the 1947 Roswell UFO incident and its role in UFO and alien conspiracy theories.",
      link: "https://en.wikipedia.org/wiki/Roswell_UFO_incident"
    },
    {
      title: "The Reptilian Elite",
      description: "Discusses the conspiracy theory that claims a race of reptilian beings controls the world.",
      link: "https://en.wikipedia.org/wiki/Reptilian_conspiracy_theory"
    },
    {
      title: "HAARP Weather Control",
      description: "Examines the claims that the HAARP facility is used for weather control.",
      link: "https://en.wikipedia.org/wiki/High_Frequency_Active_Auroral_Research_Program"
    },
    {
      title: "Crop Circles",
      description: "Explores the phenomenon of crop circles and the theories suggesting they are created by aliens or secret technologies.",
      link: "https://en.wikipedia.org/wiki/Crop_circle"
    },
    {
      title: "Magna Carta",
      description: "Covers the historical importance of the Magna Carta and the conspiracies about hidden messages or lost original copies.",
      link: "https://en.wikipedia.org/wiki/Magna_Carta"
    },
    {
      title: "Alien Abduction",
      description: "Discusses the phenomenon of alien abduction and the conspiracy theories surrounding reported encounters.",
      link: "https://en.wikipedia.org/wiki/Alien_abduction"
    },
    {
      title: "Yonaguni Monument",
      description: "Examines the underwater rock formation near Japan and theories suggesting it is a man-made ancient ruin.",
      link: "https://en.wikipedia.org/wiki/Yonaguni_Monument"
    },
    {
      title: "Pyramids of Giza",
      description: "Theories suggesting the pyramids were built by aliens or advanced ancient civilizations.",
      link: "https://en.wikipedia.org/wiki/Great_Pyramid_of_Giza"
    },
    {
      title: "Stonehenge",
      description: "Believed by some to be an ancient astronomical observatory or an alien landing site.",
      link: "https://en.wikipedia.org/wiki/Stonehenge"
    },
    {
      title: "Machu Picchu",
      description: "Theories suggest that Machu Picchu was built with extraterrestrial assistance.",
      link: "https://en.wikipedia.org/wiki/Machu_Picchu"
    },
    {
      title: "Easter Island (Rapa Nui)",
      description: "The Moai statues are believed to have been created by an ancient, technologically advanced civilization or with extraterrestrial help.",
      link: "https://en.wikipedia.org/wiki/Easter_Island"
    },
    {
      title: "Teotihuacan",
      description: "Linked to theories about extraterrestrial involvement or lost civilizations like Atlantis.",
      link: "https://en.wikipedia.org/wiki/Teotihuacan"
    },
    {
      title: "Atlantis",
      description: "Legendary city said to have been a highly advanced civilization that sank into the ocean.",
      link: "https://en.wikipedia.org/wiki/Atlantis"
    },
    {
      title: "Gobekli Tepe",
      description: "Considered by some to be the remnants of an advanced prehistoric civilization, possibly with extraterrestrial connections.",
      link: "https://en.wikipedia.org/wiki/G%C3%B6bekli_Tepe"
    },
    {
      title: "Nazca Lines",
      description: "Massive geoglyphs that have sparked theories about being signals for alien spacecraft.",
      link: "https://en.wikipedia.org/wiki/Nazca_Lines"
    },
    {
      title: "The Great Sphinx of Giza",
      description: "Some theorists suggest that the Sphinx predates Egyptian civilization and might be evidence of a lost advanced culture.",
      link: "https://en.wikipedia.org/wiki/Great_Sphinx_of_Giza"
    },
    {
      title: "Puma Punku",
      description: "Part of the Tiwanaku complex, believed by some to have been constructed by extraterrestrials.",
      link: "https://en.wikipedia.org/wiki/Pumapunku"
    },
    {
      title: "Chichen Itza",
      description: "The advanced astronomical alignments have led to theories about the involvement of advanced ancient knowledge or extraterrestrial beings.",
      link: "https://en.wikipedia.org/wiki/Chichen_Itza"
    },
    {
      title: "The Oracle Chamber (Malta)",
      description: "Built with advanced acoustic knowledge or possibly alien technology.",
      link: "https://en.wikipedia.org/wiki/%C4%A6al_Saflieni_Hypogeum"
    },
    {
      title: "The Baghdad Battery",
      description: "This ancient artifact is thought by some to be evidence that ancient civilizations had access to electricity.",
      link: "https://en.wikipedia.org/wiki/Baghdad_Battery"
    },
    {
      title: "Mount Shasta",
      description: "Mount Shasta is believed by some to be a site of mystical energies and UFO activity.",
      link: "https://en.wikipedia.org/wiki/Mount_Shasta"
    },
    {
      title: "Bermuda Triangle",
      description: "A region in the North Atlantic Ocean where numerous aircraft and ships are said to have disappeared under mysterious circumstances.",
      link: "https://en.wikipedia.org/wiki/Bermuda_Triangle"
    },
    {
      title: "Philadelphia Experiment",
      description: "Claims that a U.S. Navy destroyer was rendered invisible to radar and teleported from Philadelphia to Norfolk in 1943.",
      link: "https://en.wikipedia.org/wiki/Philadelphia_Experiment"
    },
    {
      title: "Hollow Earth Theory",
      description: "The idea that the Earth is entirely hollow or contains a substantial interior space inhabited by advanced civilizations.",
      link: "https://en.wikipedia.org/wiki/Hollow_Earth"
    },
    {
      title: "Black Knight Satellite",
      description: "Explores the theory that a mysterious, possibly extraterrestrial satellite orbits the Earth.",
      link: "https://en.wikipedia.org/wiki/Black_Knight_satellite_conspiracy_theory"
    },
    {
      title: "Ten Percent of the Brain Myth",
      description: "Explores the myth that humans only use 10% of their brains and the implications of unlocking the remaining potential.",
      link: "https://en.wikipedia.org/wiki/Ten_percent_of_the_brain_myth"
    },
    {
      title: "Attempted Assassination of Donald Trump",
      description: "Covers the conspiracy theories surrounding the attempted assassination of Donald Trump during his campaign.",
      link: "https://en.wikipedia.org/wiki/Attempted_assassination_of_Donald_Trump"
    },
    {
      title: "Sphinx Water Erosion Hypothesis",
      description: "Discusses the hypothesis that the Sphinx shows signs of water erosion, suggesting a much older origin than traditionally believed.",
      link: "https://en.wikipedia.org/wiki/Sphinx_water_erosion_hypothesis"
    },
    {
      title: "Hall of Records",
      description: "Covers the legend of a hidden Hall of Records, said to contain ancient knowledge buried beneath the Sphinx or in other ancient locations.",
      link: "https://en.wikipedia.org/wiki/Hall_of_Records"
    }
  ];

  return (
    <div className="background">
      <div className="title">EXPLORE DATA</div>
      <div className="subtitle">CONSPIRACIES LIST</div>
      <ul className="data-list">
        {conspiracies.map((item, index) => (
          <li key={index} className="conspiracy-item">
            <a href={item.link} target="_blank" rel="noopener noreferrer" className="conspiracy-link">
              <strong>{item.title}</strong>
            </a>
            <p className="conspiracy-description">{item.description}</p>
          </li>
        ))}
        <li className="conspiracy-item">
          <Link to="/timeline" style={{ textDecoration: 'none', color: 'white' }} className="conspiracy-link">
            <strong>Interactive Timeline of Conspiracy History</strong>
          </Link>
          <p className="conspiracy-description">
            Explore the timeline of major conspiracy theories throughout history.
          </p>
        </li>
      </ul>
    </div>
  );
}

export default ExploreData;
