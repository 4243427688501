import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoadingScreen.css';

const LoadingScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/home'); // Navigate to the home page after 5 seconds
    }, 5000); // Adjust timing here if needed

    return () => clearTimeout(timer); // Clear the timeout if the component unmounts
  }, [navigate]);

  return (
    <div className="loading-screen">
      <video id="loading-video" className="loading-video" src="/sine_load.mp4" autoPlay />
    </div>
  );
};

export default LoadingScreen;
