import React from 'react';
import './InteractiveMap.css'; // Import a separate CSS file for the map page

const InteractiveMap = () => {
  return (
    <div className="map-page-container">
      <h1 className="map-title">Interactive Map</h1>
      <iframe 
        src="https://app.mapline.com/map/map_439642b3/OHZCYndvUittZXllTHAzelVoYlJ4Vm9uc1VrMWc1cTBoaFdobE" 
        style={{ width: '100%', height: '500px', border: 'none' }} 
        allow="geolocation *"
        title="Interactive Map"
      ></iframe>
      <div style={{ fontSize: '10px', textAlign: 'center', marginTop: '10px' }}>
        <a href="https://mapline.com" target="_blank" rel="noopener noreferrer">Mapping by Mapline</a>
      </div>
    </div>
  );
}

export default InteractiveMap;
